import * as React from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';

/*
Common component to display a labeled text field for survey or form questions.
Usage:
    <DynamicTextFieldGroup
        questionId="Q1"
        questionText="Please provide your feedback:"
        value={values.Q1}
        onChange={handleChange}
        px={8}
        placeholder="Type your answer here..."
    />
*/

export default function DynamicTextFieldGroup({ questionId, questionText, onChange, px, placeholder, multiline, rows  }) {
    // Detect mobile view using useMediaQuery hook
    const mobileView = useMediaQuery('(max-width:600px)');

    return (
        <FormControl fullWidth>
            <Box sx={{ px: 3 }}>
                <FormLabel id={questionId} sx={{ typography: 'body1', px: px, fontWeight: 'bold', mr: mobileView ? 0 : 2, mb: 2 }}>
                    {questionText}
                </FormLabel>
                <TextField
                    id={questionId}
                    name={questionId}
                    onChange={onChange}
                    fullWidth
                    multiline={multiline}
                    rows={rows}
                    placeholder={placeholder}
                    variant="outlined"
                    sx={{px:px}}
                />
            </Box>
        </FormControl>

    );
}