import React, { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DynamicRadioGroup from "../components/survey/DynamicRadioGroup";
import DynamicYesNoGroup from "../components/survey/DynamicYesNoGroup";
import DynamicTextFieldGroup from "../components/survey/DynamicTextFieldGroup";
import DynamicCheckboxGroup from "../components/survey/DynamicCheckboxGroup";

export default function FormControlLabelPlacement() {
    const [values, setValues] = useState({
        Q1: '',
        Q2a: '',
        Q2b: '',
        Q2c: '',
        Q2d: '',
        Q2e: '',
        Q3: '',
        Q4: '',
        Q5a: '',
        Q5b: '',
        Q5c: '',
        Q6: '',
        Q7: '',
        Q8: '',
        Q9 : '',
    });


    const handleCheckboxChange = (event, value) => {
        const { name } = event.target;
        if (event.target.checked) {
            setValues((prevValues) => ({
                ...prevValues,
                [name]: [...(prevValues[name] || []), value] // Append value to existing array or create a new one
            }));
        } else {
            setValues((prevValues) => ({
                ...prevValues,
                [name]: (prevValues[name] || []).filter((item) => item !== value) // Remove value from array
            }));
        }
    };


    const handleTextFieldChange = (event) => {
        const { name, value } = event.target;
        setValues((prevValues) => ({
            ...prevValues,
            [name]: value, // Update the specific text input with its value
        }));
    };


    const handleRadioChange = (event) => {
        const { name, value } = event.target;
        setValues((prevValues) => ({
            ...prevValues,
            [name]: value, // Update the specific question with its value
        }));
    };


    const saveAsJson = () => {
        const jsonOutput = JSON.stringify(values, null, 2); // Pretty-print with 2-space indentation
        console.log('Form Data JSON:', jsonOutput);
        alert('Form data saved as JSON. Check console for details.');

        // Optional: Save as file logic
        const blob = new Blob([jsonOutput], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'survey_results.json';
        a.click();
        URL.revokeObjectURL(url);
    };



    return (
<div>
        <Box sx={{ px: 0 }}>
            <FormLabel id="Q0" sx={{typography: 'body1', fontWeight: 'bold', mx:2, p: 2, width: 'auto',  fontSize: 'h6.fontSize', color:'blue'}}>Irish Life Patient Experience Feedback</FormLabel>
        </Box>
        {/*probably need to ask this question*/}
        {/*Which of the following best describes your visit to the Irish Life Health Express Care clinic? two options 1. Visited to get treatment for myself 2. Visited to bring someone else (family, relatives or someone you know) for treatment */}
        <FormControl>
            <DynamicRadioGroup
                questionId="Q1"
                questionText="Q1. Overall, how would you rate your experience of using Irish Life Health Express Care Clinic?"
                value={values.Q1}
                onChange={handleRadioChange}
                px={3}
                numOptions={5}
                lowerRatingDescription={'Extremely Dissatisfied'}
                upperRatingDescription={'Extremely Satisfied'}
            />
            <Box sx={{ px: 3 }}>
                <FormLabel id="Q2" sx={{typography: 'body1', fontWeight: 'bold'}}>Q2. Based on your experience of using Irish Life Health Express Care Clinic, how would you rate the following: </FormLabel>
            </Box>
                <DynamicRadioGroup
                    questionId="Q2a"
                    questionText="a. Range of clinical services provided"
                    value={values.Q2a}
                    onChange={handleRadioChange}
                    px={8}
                    numOptions={5}
                    lowerRatingDescription={'Extremely Dissatisfied'}
                    upperRatingDescription={'Extremely Satisfied'}
                />
                {/*b. Professionalism & helpfulness of staff during your visit*/}
                <DynamicRadioGroup
                    questionId="Q2b"
                    questionText="b. Professionalism & helpfulness of staff during your visit"
                    value={values.Q2b}
                    onChange={handleRadioChange}
                    px={8}
                    numOptions={5}
                    lowerRatingDescription={'Extremely Dissatisfied'}
                    upperRatingDescription={'Extremely Satisfied'}
                    />
                {/*c. Ease of check-in & registration at the clinic*/}
                <DynamicRadioGroup
                    questionId="Q2c"
                    questionText="c. Ease of check-in & registration at the clinic"
                    value={values.Q2c}
                    onChange={handleRadioChange}
                    px={8}
                    numOptions={5}
                    lowerRatingDescription={'Extremely Dissatisfied'}
                    upperRatingDescription={'Extremely Satisfied'}
                />
                {/*d. Understanding your level of cover for your treatment*/}
                <DynamicRadioGroup
                    questionId="Q2d"
                    questionText="d. Understanding your level of cover for your treatment"
                    value={values.Q2d}
                    onChange={handleRadioChange}
                    px={8}
                    numOptions={5}
                    lowerRatingDescription={'Extremely Dissatisfied'}
                    upperRatingDescription={'Extremely Satisfied'}
                />
                {/*e. Explanation provided for the diagnosis, treatment & next steps*/}
                <DynamicRadioGroup
                    questionId="Q2e"
                    questionText="e. Explanation provided for the diagnosis, treatment & next steps"
                    value={values.Q2e}
                    onChange={handleRadioChange}
                    px={8}
                    numOptions={5}
                    lowerRatingDescription={'Extremely Dissatisfied'}
                    upperRatingDescription={'Extremely Satisfied'}
                />
                {/*Q3. Following your visit, how likely are you to recommend Irish Life Health Express Care Clinic to a friend or colleague?*/}
                <DynamicRadioGroup
                    questionId="Q3"
                    questionText="Q3. Following your visit, how likely are you to recommend Irish Life Health Express Care Clinic to a friend or colleague?"
                    value={values.Q3}
                    onChange={handleRadioChange}
                    px={3}
                    numOptions={5}
                    lowerRatingDescription={'Extremely Unlikely'}
                    upperRatingDescription={'Extremely Likely'}
                />
            <DynamicRadioGroup
                questionId="Q4"
                questionText="Q4. How likely are you to recommend Irish Life Health Express Care Clinic to a friend or colleague?"
                value={values.Q4}
                onChange={handleRadioChange}
                px={3}
                numOptions={10}
                lowerRatingDescription={'Extremely Unlikely'}
                upperRatingDescription={'Extremely Likely'}
            />
            <Box sx={{ px: 3 }}>
                <FormLabel id="Q5" sx={{typography: 'body1', fontWeight: 'bold'}}>Q5. How do you feel about Irish life health as an insurance provider following your visit to the Irish Life Health Express Care Clinic:  </FormLabel>
            </Box>
            <DynamicRadioGroup
                questionId="Q5a"
                questionText="a. Is a provider that looks after its customers"
                value={values.Q5a}
                onChange={handleRadioChange}
                px={8}
                numOptions={5}
                lowerRatingDescription={'Extremely Dissatisfied'}
                upperRatingDescription={'Extremely Satisfied'}
            />
            <DynamicRadioGroup
                questionId="Q5b"
                questionText="b. Provides services that help you get better faster"
                value={values.Q5b}
                onChange={handleRadioChange}
                px={8}
                numOptions={5}
                lowerRatingDescription={'Extremely Dissatisfied'}
                upperRatingDescription={'Extremely Satisfied'}
            />
            <DynamicRadioGroup
                questionId="Q5c"
                questionText="c. Is a provider that offers relevant health cover "
                value={values.Q5c}
                onChange={handleRadioChange}
                px={8}
                numOptions={5}
                lowerRatingDescription={'Extremely Dissatisfied'}
                upperRatingDescription={'Extremely Satisfied'}
            />
        {/*  Q6  We would welcome any comments you may have that can help us to improve our service. <textarea>*/}
            <DynamicTextFieldGroup
                questionId="Q6"
                questionText="Q6. We would welcome any comments you may have that can help us to improve our service"
                value={values.Q6}
                onChange={handleTextFieldChange}
                px={0}
                multiline={true}
                rows={4}
                placeholder="Plesae provide your feedback here..."
            />

        {/*  Q7 if you would like the Clinic Manager to call you to follow up on any ISSUES OR CONCERNS that you have regarding your visit Yes/No Radio */}
           <Box sx={{mb:2}}></Box>
            <DynamicYesNoGroup
                questionId="Q7"
                questionText="Q7. Would you like the Clinic Manager to call you to follow up on any ISSUES OR CONCERNS that you have regarding your visit? "
                value={values.Q7}
                onChange={handleRadioChange}
                px={3}
                numOptions={2}
            />

        {/*  Q8 How likely are you to consider taking out a health insurance policy with Irish Life Health after your visit to the Express Care Clinic 7 point scale and set a variable on load in the URL to indicate if the patient is insured via ILH  */}
            <DynamicRadioGroup
                questionId="Q8"
                questionText="Q8. How likely are you to consider taking out a health insurance policy with Irish Life Health after your visit to the Express Care Clinic? "
                value={values.Q8}
                onChange={handleRadioChange}
                px={3}
                numOptions={7}
                lowerRatingDescription={'Extremely Unlikely'}
                upperRatingDescription={'Extremely Likely'}
            />
            <DynamicCheckboxGroup
                questionId="Q9"
                questionText="Q9. Select your preferences:"
                options={['Option 1', 'Option 2', 'Option 3', 'Option 4']}
                selectedValues={values.Q9 || []}
                onChange={handleCheckboxChange}
                px={3}
            />
            <Box sx={{alignSelf: 'center'}}>
            <Button variant="contained" color="success"
                    onClick={() => {
                        saveAsJson();
                    }}
            >
                Save Response as JSON
            </Button>
            </Box>
            <Box sx={{alignSelf: 'center', mb: 4}} />

        </FormControl>
</div>
    );
}