import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';

/*
Common component to display radio boxes where you can dynamically pass in values as shown below to create for example a survey.
Usage:
    const [values, setValues] = useState({
        Q1: '',
        Q2: '',
            ......
    });

        return .....
                <DynamicRadioGroup
                    questionId="Q1"
                    questionText="Q1. Explanation provided for the diagnosis, treatment & next steps"
                    value={values.Q1}
                    onChange={handleChange}
                    px={8}
                    numOptions={5}
                    lowerRatingDescription={'Extremely Dissatisfied'}
                    upperRatingDescription={'Extremely Satisfied'}
                />

See src/pages/ILHSurvey.js for comprehensive usage.
 */


export default function DynamicRadioGroup({ questionId, questionText, value, onChange, px, numOptions, lowerRatingDescription, upperRatingDescription }) {
    // Detect mobile view using useMediaQuery hook
    const mobileView = useMediaQuery('(max-width:600px)');

    // const handleRadioChange = (event) => {
    //     const { name, value } = event.target;
    //     setValues((prevValues) => ({
    //         ...prevValues,
    //         [name]: value, // Update the specific question with its value
    //     }));
    // };

    return (
        <FormControl>
            <Box sx={{ px: px }}>
                <FormLabel id={questionId} sx={{ typography: 'body1', fontWeight: 'bold'}}>
                    {questionText}
                </FormLabel>
                <RadioGroup
                    row={!mobileView} // true for desktop, false for mobile (column layout)
                    aria-labelledby={`${questionId}-form-control-label-placement`}
                    name={questionId}
                    value={value}
                    onChange={onChange}
                >
                    <Box sx={{ typography: 'body1', fontWeight: 'light', alignSelf: 'center', mb: mobileView ? 2 : 0}}>
                        {lowerRatingDescription}
                    </Box>
                    {Array.from({ length: numOptions }, (_, index) => (
                        <FormControlLabel
                            key={`${questionId}-${index + 1}`}
                            value={`${questionId}-${index + 1}`}
                            control={<Radio />}
                            label={`${index + 1}`}
                            labelPlacement={mobileView ? 'start' : 'top'} // 'top' for mobile, 'bottom' for desktop
                            sx={{ alignSelf: 'center' }} // Adjust margin as needed
                        />
                    ))}

                    <Box sx={{ typography: 'body1', fontWeight: 'light', alignSelf: 'center', mb: mobileView ? 2 : 0}}>
                        {upperRatingDescription}
                    </Box>
                </RadioGroup>
            </Box>
        </FormControl>
    );
}
