import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';

/*
Common component to display a labeled checkbox group for survey or form questions.
Usage:
    <DynamicCheckboxGroup
        questionId="Q2"
        questionText="Select your preferences:"
        options={['Option 1', 'Option 2', 'Option 3']}
        selectedValues={selectedValues}
        onChange={handleCheckboxChange}
        px={8}
    />
*/

export default function DynamicCheckboxGroup({ questionId, questionText, options, selectedValues, onChange, px }) {
    // Detect mobile view using useMediaQuery hook
    const mobileView = useMediaQuery('(max-width:600px)');

    return (
        <FormControl>
            <Box sx={{ px: px }}>
                <FormLabel id={questionId} sx={{ typography: 'body1', fontWeight: 'bold' }}>
                    {questionText}
                </FormLabel>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: mobileView ? 'column' : 'row',
                        flexWrap: 'wrap',
                        mt: 1,
                    }}
                >
                    {options.map((option, index) => (
                        <FormControlLabel
                            key={`${questionId}-${option}`}
                            control={
                                <Checkbox
                                    name={questionId} // Add the name attribute here
                                    checked={selectedValues.includes(option)}
                                    onChange={(e) => onChange(e, option)}
                                />
                            }
                            label={option}
                            sx={{ mr: 2, mb: mobileView ? 1 : 0 }} // Margin adjustments for mobile and desktop
                        />
                    ))}
                </Box>
            </Box>
        </FormControl>
    );
}
